import { ProdEnvironment } from './environment.model';

const environment: ProdEnvironment = {
  countryCodeGBR: 'GBR',
  countryCodeUSA: 'USA',
  base: 'uk',
  production: true,
  usDomainUrl: 'https://enableall.com/us',
  ukDomainUrl: 'https://enableall.com/uk',
  serverUrl: 'https://enableallapiprod.azurewebsites.net',
  fabricMiddlewareTargetApi: 'https://enableallapiprod.azurewebsites.net/Api',
  fabricPriceTargetApi: 'https://enableallapiprod.azurewebsites.net/Api',
  fabricPriceListId: {
    priceListId: 100003,
  },
  synerise: {
    collectionId: '66222f14344c200008923dc0',
    indexId: 'e6cfc0b5939e867550cfd59f18c4cb031716207661',
    token: 'd4f2a5f2-6d9e-4ab2-9053-45497728a137',
    recommendation : "97RKhZL3ZCbw"
  },
  fabricCategoryId: {
    categoryId: '63e128a7d0314300084413cd',
  },
  stripe: {
    key: 'pk_live_51KtvmPHhjXma0uG3wAzYUPF33zdRuAVyMXkFHsluom01kHUhlBMR7jXKq1MgR6JtCQnFn7qKrNEenmm00OSxNzFk00t8NfyO9a',
    country: 'GB',
  },
  algolia: {
    appId: 'JBFYGABGIH',
    apiKey: '6c0db344757e1a1548dff0cc7da19cb3',
    indexName: '63d84ddb0973500008ba7433-prod01-commerce-search-product-en-gb',
  },
};

export default environment;
